<template>
  <div class="Table">
    <div class="dashboard__container">
      <div class="table">
        <appTable
          :header="['日期', '當月利率']"
          :dataArray="InterestRate"
          :dataProp="['date', 'rate']"
        ></appTable>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import appTable from "../appTable.vue";
export default {
  name: "dashboard",
  components: {
    appTable,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      KeyMap: "getKeyMap",
      InvestInfo: "getInvestInfo",
      InterestRate: "getInterestRate",
    }),
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";

.Table {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    @include phone {
      width: 100%;
    }
  }
  .table {
    width: 360px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    @include phone {
      width: 95%;
    }
  }
}
</style>
