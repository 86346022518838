<template>
  <div class="appTable">
    <div class="header">
      <div class="item" v-for="(item, index) in header" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="row" v-for="(item, index) in dataArray" :key="index">
      <div
        class="item"
        v-for="(prop, prop_index) in dataProp"
        :key="prop_index"
      >
        {{ item[prop] }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "appTable",
  props: {
    dataArray: {
      required: true,
    },
    dataProp: {
      required: true,
    },
    header: {
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "@/scss/main.scss";

.appTable {
  display: table;
  text-align: center;
  background-color: $white;
  width: 100%;
  max-width: 500px;
  margin: 10px;
  padding: 20px;
  box-shadow: rgb(20 21 26 / 4%) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px,
    rgb(20 21 26 / 10%) 0px 0px 1px;
  border-radius: 6px;
  box-sizing: border-box;
  .item {
    display: table-cell;
    font-size: 16px;
    text-align: left;
    padding: 20px 0 20px 5px;
    border-bottom: solid 1px $borderGrey;
  }
  .header {
    display: table-row;
  }
  .row {
    display: table-row;
  }
  .row:last-child {
    .item {
      border-bottom: none;
    }
  }
}
</style>
